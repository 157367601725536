import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';

const Bodystyles = styled.div`
  .grid-container {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    padding-right: 30px;
  }
  a {
    color: #14284e !important;
  }

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .titel {
    font-weight: 700;
    font-size: 70px;
    color: #14284e;
    text-align: center;
    margin-top: 150px;
    width: 100vw;
  }

  form {
    padding-left: 30px;
    padding-right: 30px;
  }

  .inputstyle {
    max-width: 600px;
    width: 100%;
    height: 47px;
    background: #ffffff;
    border: 1px solid #14284e !important;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    color: #14284e;
    font-size: 18px;
  }

  .inputstyle:nth-of-type(5) {
    max-width: 400px;
    width: 100%;
    height: 400px;
    background: #ffffff;
    border: 1px solid #14284e !important;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    padding-top: 10px;
    color: #14284e;
    font-size: 18px;
  }

  label {
    font-weight: normal;
    font-size: 22px;
    color: #14284e;
  }

  ::placeholder {
    font-weight: normal;
    font-size: 22px;
    color: #14284e;
  }

  button {
    margin: 30px auto 60px auto;
  }

  #message {
    padding: 10px 15px;
    color: rgba(0, 0, 0, 0.6);
    max-width: 590px;
  }

  textarea {
    resize: none;
    height: 300px !important;
  }

  @media (min-width: 660px) {
    form {
      margin: auto;
      min-width: 600px;
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 50px !important;
    }

    label {
      font-size: 16px;
    }

    .inputstyle,
    #message {
      width: 100%;
      align-items: center;
    }

    #message {
      padding: 0;
      padding-left: 15px;
    }

    form {
      margin: 0% 10%;
      padding-left: 35px !important;
    }

    .inputstyle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .purplebutton {
      width: 100%;
    }
  }
`;

const Contact = () => (
  <Layout>
    <Bodystyles>
      <div className="main">
        <div className="grid-container">
          <h1 className="titel">Contact</h1>
          <form
            name="contact"
            method="POST"
            action="https://appartementbrixen.mailer.lemone.cloud"
            encType="application/x-www-form-urlencoded "
          >
            <input type="hidden" name="_redirectUrl" value="https://appartementbrixen.nl/bedankt" />

            <label htmlFor="name">
              Naam
              <br />
              <input
                required
                className="inputstyle"
                type="text"
                id="name"
                name="Naam"
                placeholder="Naam"
              />
            </label>
            <br />
            <br />
            <br />
            <label htmlFor="number">
              Telefoonnummer
              <br />
              <input
                name="Telefoonnummer"
                className="inputstyle"
                type="tel"
                id="number"
                placeholder="Telefoonnummer"
              />
            </label>
            <br />
            <br />
            <br />
            <label htmlFor="email">
              E-mail
              <br />
              <input
                required
                className="inputstyle"
                type="email"
                id="email"
                placeholder="E-mail"
                name="email"
              />
            </label>
            <br />
            <br />
            <br />
            <label htmlFor="message">
              Bericht
              <br />
              <textarea name="tekst" className="inputstyle" id="message" />
            </label>
            <br />
            <br />
            <br />
            <button
              id="send"
              className="purplebutton"
              type="submit"
            >
              Verstuur
            </button>
          </form>
        </div>
      </div>
    </Bodystyles>
  </Layout>
);

export default Contact;
